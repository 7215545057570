import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  FeatureHero,
  H1,
  H3,
  Span,
  Paragraph,
  ContactForm,
  Button,
  TwoColumnContainer
} from "../components/styled/components"
import { industries } from "../constants/industries"
import map from "../images/Tests_Map.png"
import lowes from "../images/Lowes Logo.png"
import pnb from "../images/P&B Logo.png"
import ggt from "../images/GGT Logo.png"
import flowserve from "../images/Flowserve logo.png"
import bmsc from "../images/BMSC Logo.png"
import pcr from "../images/pcr_test.png"
import rapid from "../images/rapid_test.png"
import kiosk from "../images/kiosk.png"
import mobile from "../images/mobile_testing.png"
import provider from "../images/provider.png"
import contact from "../images/contact_icon.png"

const WellToWork = () => {
  return (
    <Layout>
      <SEO title="Well to Work Covid-19 Employee Testing" />

      <TwoColumnContainer className="wellToWork">
        <div className="header">
          <H1 center>
            A Safe Return with <br /> Well to Work
          </H1>
          <Paragraph fontSize="20px">
            Deploy a COVID system to protect your employees and your bottom line.<br /> 
            <b>With over 1,000,000 tests performed,</b> we're a trusted partner in a proactive response for your business.
          </Paragraph>
        </div>
        <div className="form">
          <ContactForm
            name="Well to Work"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <H3 fontSize="32px" mb={2}>
              Let's Get Started
            </H3>
            <input type="hidden" name="form-name" value="Well to Work" />
            <p hidden>
              <label>
                Don’t fill this out: <input name="bot-field" />
              </label>
            </p>
            <div>
              <input
                placeholder=" Full Name"
                aria-label="Full Name"
                type="text"
                name="fullName"
                required
              />
            </div>
            <div>
              <input
                placeholder=" Email"
                aria-label="Email"
                type="email"
                name="email"
                required
              />
            </div>
            <div>
              <input
                placeholder=" Phone Number"
                aria-label="Phone Number"
                type="phone"
                name="phone"
                required
              />
            </div>
            <div>
              <input
                placeholder=" Organization"
                aria-label="Organization"
                type="text"
                name="organization"
                required
              />
            </div>
            <div>
              <input
                placeholder=" ZIP Code"
                aria-label="ZIP Code"
                type="text"
                name="zipCode"
                pattern="[0-9]{5}"
                required
              />
            </div>
            <div className="select">
              <select
                placeholder="Industry"
                aria-label="Industry"
                name="industry"
                required
              >
                <option value="" disabled selected>
                  Industry
                </option>
                {industries.map(industry => (
                  <option>{industry}</option> 
                ))}
              </select>
            </div>
            <div className="select">
              <select
                placeholder="Number of Employees"
                aria-label="Number of Employees"
                name="numberOfEmployees"
                required
              >
                <option value="" disabled selected>
                  Number of Employees
                </option>
                <option>&lt;50</option>
                <option>50-150</option>
                <option>151-350</option>
                <option>351-500</option>
                <option>500+</option>
              </select>
            </div>
            <Button mt={3} textAlign="center" type="submit">
              Submit
            </Button>
          </ContactForm>
        </div>
      </TwoColumnContainer>

      <div className="corporatePromo">
        <div>
          <H1 textAlign="center">
            We'll build a customized, cost-effective plan for your business
          </H1>
          <Paragraph textAlign="center">
            WellHealth is the first choice in coronavirus testing for cities,
            colleges, companies, local non-profits and community organizations
            across the country.
          </Paragraph>
          <Paragraph textAlign="center">
            Using developed algorithms, we can protect your workforce
            with the minimal amount of testing and optimal cost.
          </Paragraph>
        </div>
      </div>

      <FeatureHero className="testingMap">
        <section>
          <H1 center>
            Over 1,000,000 tests <br /> delivered across the nation
          </H1>
          <img src={map} alt="Testing Map" />
        </section>
      </FeatureHero>
      
      <div className="testingPartners">
        <H1 textAlign="center">
          Official Testing Partners
        </H1>
        <div className="partners">
          <div className="corporate">
            <H3 mb={3} textAlign="center">Corporate</H3>
            <div className="corporateRow row1">
              <div>
                <img src={lowes} alt="Lowe's" />
              </div>
              <div>
                <img src={pnb} alt="Pax and Beneficia" />
              </div>
              <div>
                <img src={ggt} alt="GoGetTested" />
              </div>
            </div>
            <div className="corporateRow row2">
              <div style={{ marginRight: 0 }}>
                <img src={flowserve} alt="Flowserve" />
              </div>
              <div style={{ marginLeft: 0 }}>
                <img src={bmsc} alt="BMSC" />
              </div>
            </div>
          </div>
          <div className="stateGov">
            <H3 mb={3} textAlign="center">State Government</H3>
            <table>
              <tbody>
                <tr>
                  <td>Arizona</td>
                  <td>Texas</td>
                </tr>
                <tr>
                  <td>Nevada</td>
                  <td>South Carolina</td>
                </tr>
                <tr>
                  <td>Kansas</td>
                  <td>North Carolina</td>
                </tr>
                <tr>
                  <td>Missouri</td>
                  <td>Massachussetts</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="testingExperience">
        <H1 mt={5} textAlign="center">Complete Employee Covid-19 <br /> Testing Experience</H1>
        <table>
          <tbody>
            <tr>
              <td>
                <img src={pcr} alt="PCR Test" />
              </td>
              <td>
                <img src={rapid} alt="Rapid Test" />
              </td>
              <td>
                <img src={kiosk} alt="Kiosks" />
              </td>
              <td>
                <img src={mobile} alt="Mobile Testing" />
              </td>
              <td>
                <img src={provider} alt="Post-COVID Care" />
              </td>
            </tr>
            <tr>
              <td>
                <span>PCR Tests</span>
              </td>
              <td>
                <span>Rapid Tests</span>
              </td>
              <td>
                <span>Kiosks</span>
              </td>
              <td>
                <span>Mobile Testing</span>
              </td>
              <td>
                <span>Post-COVID Care</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="testingFeatures">
        <H1>WellHealth Testing Includes:</H1>
        <hr></hr><br />
        <ul>
          <li>A digital platform powered by GoGetTested.</li>
          <li>
            Group codes for companies, pre-op testing, large groups, and nursing
            homes to test and receive results directly mitigating the possible spread.
          </li>
          <li>
            Automated scheduling and resulting, streamlining the entire testing process.
          </li>
          <li>
            A dashboard with live updates on testing location, available slots,
            average resulting time, wait times, and Spanish capabilities to maintain
            constant visibility into testing operations.
          </li>
          <li>
            A call center to handle customer service issues and manual scheduling
            further assisting all populations.
          </li>
        </ul>
      </div>

      <div className="responseUnits">
        <div>
          <H1 mt={3}>
            WellHealth COVID Response Units
            can be rapidly and flexibly deployed
          </H1>
          <br /><hr></hr>
          <Paragraph>
            With testing sites across seven states and counting, accessibility for
            everyone means the ability to change locations and provide responsive care.
          </Paragraph>
        </div>
      </div>

      <div className="corporateContact">
        <H1 mt={3} textAlign="center">
          Contact WellHealth to build your
          corporate COVID response
        </H1>
        <div className="contactOptions">
          <div style={{ width: "80%", margin: "auto" }}>
            <img src={contact} alt="Contact Options" />
            <table>
              <tbody>
                <tr><td><Span>Call us at <a href="tel:2818176060">(281) 817-6060</a></Span></td></tr>
                <tr><td><Span>Email us at <a href="mailto:hello@wellhealth.studio">hello@wellhealth.studio</a></Span></td></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default WellToWork
