export const industries = [
  'Accounting',
  'Administration & Office Support',
  'Advertising, Arts & Media',
  'Banking & Financial Services',
  'Call Centre & Customer Service',
  'Community Services & Development',
  'Construction',
  'Consulting & Strategy',
  'Design & Architechture',
  'Education & Training',
  'Engineering',
  'Farming, Animals & Conservation',
  'Government & Defence',
  'Healthcare & Medical',
  'Hospitality & Tourism',
  'Human Resources & Recruitment',
  'Information & Communication Technology',
  'Insurance & Superannuation',
  'Legal',
  'Manufacturing, Transport & Logistics',
  'Marketing & Communications',
  'Mining, Resources & Energy',
  'Real Estate & Property',
  'Retail & Consumer Products',
  'Sales',
  'Science & Technology',
  'Sport & Recreation',
  'Trades & Services',
  'Other'
];